import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRamp: BrandVariants = {
    10: "#060200",
    20: "#251206",
    30: "#3F1A0C",
    40: "#55200E",
    50: "#6B2610",
    60: "#822C10",
    70: "#9A3110",
    80: "#B33610",
    90: "#CC3C10",
    100: "#E5410E",
    110: "#FC4B15",
    120: "#FF6B3A",
    130: "#FF875C",
    140: "#FF9F7C",
    150: "#FFB69B",
    160: "#FFCBB8"
};

const candcBrandRamp: BrandVariants = {
    10:  "#B33610",
    20:  "#BC3316",
    30:  "#C5311D",
    40:  "#CE2F23",
    50:  "#D72D2A",
    60:  "#E02B30",
    70:  "#E92937",
    80:  "#F3273E",
    90:  "#F43B4D",
    100: "#F6505C",
    110: "#F7646B",
    120: "#F9797B",
    130: "#FA8D8A",
    140: "#FCA299",
    150: "#FDB6A8",
    160: "#FFCBB8"
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#fa450d',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#fa450d',
};

export const candcLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(candcBrandRamp),
    colorMeBackground: '#fa450d',
};

export const candcDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(candcBrandRamp),
    colorMeBackground: '#fa450d',
};

export const customTokens = themeToTokensObject(candcLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
