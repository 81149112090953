import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    Dropdown,
    Input,
    InputOnChangeData,
    Label,
    Spinner,
    TableColumnDefinition,
    TableRowId,
    createTableColumn,
    makeStyles,
    shorthands,
    tokens,
    Option,
    OptionOnSelectData,
    SelectionEvents,
} from '@fluentui/react-components';
import { DismissCircle20Regular, SaveRegular, Search20Regular } from '@fluentui/react-icons';
import debug from 'debug';
import * as React from 'react';
import { Constants } from '../../../Constants';
import { useChat } from '../../../libs/hooks';
import { useSites } from '../../../libs/hooks/useSite';
import { ISiteInfo } from '../../../libs/models/SelectedSites';
import CacheUtils from '../../../libs/utils/CacheUtils';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { editConversationSiteSource } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, customTokens } from '../../../styles';

const log = debug(Constants.debug.root).extend('sites-tab');

export type SiteMap = Record<string, ISiteInfo[]>;

const useClasses = makeStyles({
    outerWrapper: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        ...shorthands.margin('10px 0'),
    },
    header: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: 'bold',
        ...shorthands.margin('5px 0'),
    },
    description: {
        fontSize: '1em',
        ...shorthands.margin('10px', '0'),
    },
    sitesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        ...shorthands.margin('10px 0'),
    },
    selectedSites: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '10px',
        columnGap: '10px',
    },
    selectedSite: {
        alignItems: 'start',
        columnGap: '10px',
        display: 'flex',
    },
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        ...shorthands.margin('10px 0'),
    },
    table: {
        overflowY: 'auto',
        maxWidth: '100px',
    },
    sitesPillsContainer: {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '95%',
        flexWrap: 'wrap',
    },
    sitesPillDesc: {
        marginRight: '10px',
        fontWeight: '600',
    },
    sitesPills: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    sitesPill: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        ...shorthands.margin('8px', '10px', '5px', '0'),
        opacity: 1,
        ...shorthands.padding('1px', '4px', '1px', 0),
        ...shorthands.borderRadius('20px'),
        width: 'auto',
        color: '#ffffff',
        textAlign: 'center',
    },
    sitesPillValue: {
        ...shorthands.flex(1, 0, 'auto'),
        lineHeight: '18px',
        ...shorthands.padding('0px', '5px', '1px', '10px'),
        fontWeight: 400,
        ...Breakpoints.small({
            fontSize: '12px',
        }),
    },
    sitesPillIcon: {
        height: '20px',
        cursor: 'pointer',

        '&:hover': {
            '> svg': {
                color: '#ffffff',
            },
        },
    },
    moreArchivePills: {
        marginLeft: '5px',
        ...shorthands.padding('4px', '0', '0px', '0'),
        color: 'black',
        cursor: 'pointer',
        fontSize: '30px',

        '&:hover': {
            '> svg': {
                color: customTokens.colorBrandBackground,
            },
        },
    },
    dataGridRow: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#DBAA96',
        },
    },
    searchBoxLabel: {
        marginTop: '10px',
    },
    filterBoxLabel: {
        marginTop: '10px',
    },
    searchBox: {
        maxWidth: '300px',
        marginBottom: '20px',
    },
    filterBox: {
        maxWidth: '300px',
    },
    tooManySitesSelected: {
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        fontSize: '14px',
        fontWeight: '400',
        marginBottom: '20px',
    },
});

const parseDateString = (dateString: string) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hours, minutes, seconds);
};

const columns: Array<TableColumnDefinition<ISiteInfo>> = [
    createTableColumn({
        columnId: 'siteName',
        compare: (a, b) => a.siteName.localeCompare(b.siteName),
        renderHeaderCell: () => {
            return <span style={{ fontWeight: 500 }}>Name</span>;
        },
        renderCell: (item) => {
            return item.siteName;
        },
    }),
    createTableColumn({
        columnId: 'siteUrl',
        compare: (a, b) => a.siteUrl.localeCompare(b.siteUrl),
        renderHeaderCell: () => {
            return <span style={{ fontWeight: 500 }}>Url</span>;
        },
        renderCell: (item) => {
            return (
                <a target="_blank" rel="noreferrer" href={item.siteUrl} style={{ wordBreak: 'break-word' }}>
                    {item.siteUrl}
                </a>
            );
        },
    }),
    createTableColumn({
        columnId: 'siteCategory',
        compare: (a, b) => (a.siteCategory ?? '').localeCompare(b.siteCategory ?? ''),
        renderHeaderCell: () => {
            return <span style={{ fontWeight: 500 }}>Category</span>;
        },
        renderCell: (item) => {
            return item.siteCategory;
        },
    }),
    createTableColumn({
        columnId: 'siteCreatedDateTime',
        compare: (a, b) => {
            const dateA = parseDateString(a.siteCreatedDateTime ?? '');
            const dateB = parseDateString(b.siteCreatedDateTime ?? '');

            return dateA.getTime() - dateB.getTime();
        },
        renderHeaderCell: () => {
            return <span style={{ fontWeight: 500 }}>Created</span>;
        },
        renderCell: (item) => {
            return item.siteCreatedDateTime;
        },
    }),
];

const columnSizingOptions = {
    siteName: {
        defaultWidth: 180,
        minWidth: 180,
    },
    siteUrl: {
        defaultWidth: 450,
        minWidth: 300,
    },
    siteCategory: {
        defaultWidth: 100,
        minWidth: 100,
    },
    siteCreatedDateTime: {
        defaultWidth: 100,
        minWidth: 100,
    },
};

export const buildSiteMap = (sites: ISiteInfo[]): Record<string, ISiteInfo[]> => {
    const siteMap: SiteMap = {};

    sites.forEach((site) => {
        const urlParts = site.siteUrl.split('/');

        if (urlParts.length > 5) {
            const secondLevel = urlParts.slice(0, 5).join('/');
            if (!siteMap.hasOwnProperty(secondLevel)) {
                siteMap[secondLevel] = [];
            }
            siteMap[secondLevel].push(site);
        } else {
            if (!siteMap.hasOwnProperty(site.siteUrl)) {
                siteMap[site.siteUrl] = [];
            } else {
                siteMap[site.siteUrl].push(site);
            }
        }
    });

    return siteMap;
};

export const SitesTab: React.FC = () => {
    const classes = useClasses();
    const [indexedSites, setIndexedSites] = React.useState<ISiteInfo[]>([]);
    const [visibleSites, setVisibleSites] = React.useState<ISiteInfo[]>([]);
    const [filteredSites, setFilteredSites] = React.useState<ISiteInfo[]>([]);
    const [selectedSites, setSelectedSites] = React.useState<ISiteInfo[]>([]);
    const [selectedRows, setSelectedRows] = React.useState(new Set<TableRowId>([]));
    const [categories, setCategories] = React.useState<string[]>([]);
    const [searchBoxValue, setSearchBoxValue] = React.useState<string>('');
    const [loading, setLoading] = React.useState(true);
    const sitesService = useSites();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const dataCacheKey = `SitesTab.GetSites.${activeUserInfo?.id}`;

    const chatState = conversations[selectedId];

    const dispatch = useAppDispatch();
    const chat = useChat();

    React.useEffect(() => {
        // Check if the data is in the cache then use from the cache else fetch the data from the server
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const cachedData: ISiteInfo[] | null = CacheUtils.getStoredDataByKey(dataCacheKey, true);

        if (cachedData !== null && cachedData.length > 0) {
            setIndexedSites(cachedData);

            const siteMap: SiteMap = buildSiteMap(cachedData);
            const sites = cachedData.filter((item) => Object.keys(siteMap).includes(item.siteUrl));

            setVisibleSites(sites);

            const categories = Array.from(new Set(sites.map((site) => site.siteCategory ?? '')))
                .filter((c) => c)
                .sort();

            setCategories(categories);
            setLoading(false);

            return;
        }

        sitesService
            .getSites()
            .then((results: ISiteInfo[]) => {
                const items: ISiteInfo[] = [];

                results.forEach((site: ISiteInfo) => {
                    items.push({
                        siteName: site.siteName,
                        siteUrl: site.siteUrl,
                        siteId: site.siteId,
                        siteCategory: site.siteCategory,
                        siteCreatedDateTime: site.siteCreatedDateTime,
                    });
                });

                items.sort((a, b) => a.siteName.localeCompare(b.siteName));

                CacheUtils.setStoredDataByKey(dataCacheKey, items, CacheUtils.dataCacheExpires24Hours, true);

                setIndexedSites(items);

                const siteMap: SiteMap = buildSiteMap(items);
                const sites = items.filter((item) => Object.keys(siteMap).includes(item.siteUrl));

                setVisibleSites(sites);

                const categories = Array.from(new Set(sites.map((site) => site.siteCategory ?? '')))
                    .filter((c) => c)
                    .sort();

                setCategories(categories);
                setLoading(false);
            })
            .catch((error) => {
                const message = `Error submitting chat input: ${(error as Error).message}`;
                log(message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!chatState.selectedSites) return;

        const selectedSites = chatState.selectedSites ?? ([] as ISiteInfo[]);
        const siteMap: SiteMap = buildSiteMap(selectedSites);
        const sites = selectedSites.filter((item) => Object.keys(siteMap).includes(item.siteUrl));

        setSelectedRows(new Set<TableRowId>(sites.map((site) => site.siteId)));
        setSelectedSites(sites);
    }, [chatState.selectedSites]);

    const onSelectionChange: DataGridProps['onSelectionChange'] = (_e, { selectedItems }) => {
        setSelectedRows(selectedItems);
    };

    const updateConversationSiteSource = async (updatedSelectedSites: ISiteInfo[]) => {
        await chat.editChat(
            selectedId,
            chatState.title,
            chatState.systemDescription,
            chatState.memoryBalance,
            chatState.chatScope,
            chatState.isHidden,
            updatedSelectedSites,
        );

        dispatch(
            editConversationSiteSource({
                id: selectedId,
                siteSources: updatedSelectedSites,
            }),
        );
    };

    const onSavePreference = () => {
        const updatedSelectedSites = visibleSites.filter((site) => selectedRows.has(site.siteId));
        const sitesToSearch: ISiteInfo[] = [];

        const siteMap: SiteMap = buildSiteMap(indexedSites);
        const sites = updatedSelectedSites.filter((item) => Object.keys(siteMap).includes(item.siteUrl));

        sites.forEach((site) => {
            const siteUrl = site.siteUrl;
            if (siteMap.hasOwnProperty(siteUrl) && siteMap[siteUrl].length > 0) {
                sitesToSearch.push(...siteMap[siteUrl]);
            } else {
                sitesToSearch.push(site);
            }
        });

        setSelectedSites(sites);

        updateConversationSiteSource(sitesToSearch).catch((error) => {
            log('An error occured whilst editing chat state', error);
        });
    };

    const onRemoveSelection = (selectedSiteId: string) => {
        const updatedSelectedSites: ISiteInfo[] = selectedSites.filter((site) => site.siteId !== selectedSiteId);
        setSelectedSites(updatedSelectedSites);
        updateConversationSiteSource(updatedSelectedSites).catch((error) => {
            log('An error occured whilst editing chat state', error);
        });
    };

    const onSearchBoxChange = (_e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const searchValue = data.value.toLowerCase();

        setSearchBoxValue(searchValue);

        if (searchValue === '') {
            setFilteredSites([]);
        } else if (searchValue.length > 2) {
            const filteredSites = visibleSites.filter(
                (site) =>
                    site.siteName.toLowerCase().includes(searchValue) ||
                    site.siteUrl.toLowerCase().includes(searchValue) ||
                    (site.siteCategory ?? '').toLowerCase().includes(searchValue) ||
                    (site.siteCreatedDateTime ?? '').toLowerCase().includes(searchValue),
            );

            setFilteredSites(filteredSites);
        }
    };

    const onOptionSelect = (_ev: SelectionEvents, data: OptionOnSelectData) => {
        if (data.optionValue) {
            const allSelectedCategories = data.selectedOptions;

            const filteredSites = visibleSites.filter((site) =>
                allSelectedCategories.includes(site.siteCategory ?? ''),
            );

            setFilteredSites(filteredSites);
        } else {
            setFilteredSites([]);
        }
    };

    return (
        <div style={{ margin: '25px', height: '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
            <div className={classes.outerWrapper}>
                {selectedSites.length > 0 && (
                    <div className={classes.sitesWrapper}>
                        <div className={classes.header}>{`Selected site${selectedSites.length > 1 ? 's' : ''}:`}</div>
                        <div className={classes.sitesPillsContainer}>
                            <div className={classes.sitesPills}>
                                {selectedSites.map((site) => (
                                    <div
                                        key={site.siteId}
                                        className={classes.sitesPill}
                                        style={{ backgroundColor: tokens.colorBrandBackground }}
                                    >
                                        <div className={classes.sitesPillValue}>{site.siteName}</div>
                                        <div
                                            className={classes.sitesPillIcon}
                                            onClick={() => {
                                                onRemoveSelection(site.siteId);
                                            }}
                                        >
                                            <DismissCircle20Regular color={'#ffffff'} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {loading ? (
                    <Spinner label="Fetching indexed SharePoint sites..." labelPosition="below" />
                ) : (
                    <>
                        <div className={classes.tableWrapper}>
                            <div className={classes.header}>All Indexed Sites</div>
                            {filteredSites.length === 0 && visibleSites.length === 0 && <div>No sites found</div>}

                            {visibleSites.length > 0 && (
                                <>
                                    <Label htmlFor="searchBox" className={classes.filterBoxLabel}>
                                        Filter SharePoint sites by category
                                    </Label>
                                    <Dropdown
                                        id="filterBox"
                                        className={classes.filterBox}
                                        multiselect
                                        onOptionSelect={onOptionSelect}
                                    >
                                        {categories.map((category) => (
                                            <Option key={category} text={category} value={category}>
                                                {category}
                                            </Option>
                                        ))}
                                    </Dropdown>
                                    <Label htmlFor="searchBox" className={classes.searchBoxLabel}>
                                        Search SharePoint sites
                                    </Label>
                                    <Input
                                        contentBefore={<Search20Regular />}
                                        placeholder="Type name, URL or category to search..."
                                        id="searchBox"
                                        className={classes.searchBox}
                                        onChange={onSearchBoxChange}
                                    />
                                    <DataGrid
                                        items={
                                            filteredSites.length
                                                ? filteredSites
                                                : searchBoxValue && filteredSites.length === 0
                                                  ? []
                                                  : visibleSites
                                        }
                                        columns={columns}
                                        sortable
                                        selectionMode="multiselect"
                                        getRowId={(item: ISiteInfo) => {
                                            return item.siteId;
                                        }}
                                        selectedItems={selectedRows}
                                        focusMode="composite"
                                        resizableColumns
                                        onSelectionChange={onSelectionChange}
                                        columnSizingOptions={columnSizingOptions}
                                        className={classes.table}
                                    >
                                        <DataGridHeader>
                                            <DataGridRow
                                                selectionCell={{
                                                    checkboxIndicator: { 'aria-label': 'Select all rows' },
                                                }}
                                            >
                                                {({ renderHeaderCell }) => (
                                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                                )}
                                            </DataGridRow>
                                        </DataGridHeader>
                                        <DataGridBody<ISiteInfo>>
                                            {({ item, rowId }) => (
                                                <DataGridRow<ISiteInfo>
                                                    key={rowId}
                                                    selectionCell={{
                                                        checkboxIndicator: { 'aria-label': 'Select row' },
                                                    }}
                                                    className={classes.dataGridRow}
                                                >
                                                    {({ renderCell }) => (
                                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                                    )}
                                                </DataGridRow>
                                            )}
                                        </DataGridBody>
                                    </DataGrid>
                                </>
                            )}

                            {searchBoxValue && filteredSites.length === 0 && visibleSites.length > 0 && (
                                <div>No sites found for search criteria</div>
                            )}
                        </div>
                        <div>
                            {selectedRows.size > 10 && (
                                <div className={classes.tooManySitesSelected}>You can select maximum of 10 sites</div>
                            )}
                            <Button
                                disabled={selectedRows.size == 0 || selectedRows.size > 10}
                                appearance="primary"
                                icon={<SaveRegular />}
                                onClick={onSavePreference}
                            >
                                Save selection{selectedRows.size > 1 ? 's' : ''}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
