import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { ChatMessageType } from '../models/ChatMessage';
import { IAskVariables } from '../semantic-kernel/model/Ask';
import { SitesService } from '../services/SitesService';
import { ISiteInfo } from '../models/SelectedSites';

export interface GetResponseOptions {
    messageType: ChatMessageType;
    value: string;
    chatId: string;
    contextVariables?: IAskVariables[];
}

export const useSites = () => {
    const dispatch = useAppDispatch();
    const { instance, inProgress } = useMsal();
    const sitesService = new SitesService(process.env.REACT_APP_BACKEND_URI as string);

    const getSites = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            return await sitesService.getSitesAsync(accessToken).then((result: ISiteInfo[]) => {
                return result;
            });
        } catch (e: any) {
            const errorMessage = `Unable to create new chat. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }

        return [];
    };

    return {
        getSites,
    };
};

function getErrorDetails(e: any) {
    return e instanceof Error ? e.message : String(e);
}