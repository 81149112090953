import { ISiteInfo } from '../models/SelectedSites';
import { BaseService, getCacheQueryString } from './BaseService';

export class SitesService extends BaseService {
    public getSitesAsync = async (accessToken: string) => {
        return await this.getResponseAsync<ISiteInfo[]>(
            {
                commandPath: `sites/getSites${getCacheQueryString('clearCache', '?')}`,
                method: 'GET',
            },
            accessToken,
        );
    };
}
